import { getStoredTrackingData } from '@digital-spiders/tracking-data';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import { Closer } from '../components/ui/modules/CloserModule';
import { ROOT_DOMAIN, VIDEO_TYPES_MAP } from '../constants';

export function getVideoTypeByUrl(url: string): keyof typeof VIDEO_TYPES_MAP {
  const type = Object.values(VIDEO_TYPES_MAP).find(({ formatRegex }) =>
    url.trim().match(formatRegex),
  )?.type;
  if (!type) {
    throw new Error(
      `Got video url "${url}" that didn't match any known type: ${Object.keys(VIDEO_TYPES_MAP).join(
        ', ',
      )}`,
    );
  }
  return type;
}

function getDaySuffix(day: number): string {
  if (day > 3 && day < 21) {
    return 'th';
  } else if (day % 10 === 1) {
    return 'st';
  } else if (day % 10 === 2) {
    return 'nd';
  } else if (day % 10 === 3) {
    return 'rd';
  } else {
    return 'th';
  }
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  const [month, day, year] = formattedDate.split(' ');

  const dayNumber = parseInt(day.replace(',', ''), 10);
  const daySuffix = getDaySuffix(dayNumber);

  return `${month} ${dayNumber}${daySuffix} ${year}`;
}

interface AllClosersQueryData {
  allSanityCloser: {
    nodes: Array<Closer>;
  };
}

export function useCloser(): Closer | null | undefined {
  const closersData = useStaticQuery<AllClosersQueryData>(graphql`
    {
      allSanityCloser(filter: { slug: { current: { ne: null } } }) {
        nodes {
          name
          slug {
            current
          }
          image {
            ...SanityImage
          }
          results {
            number
            subtitle
          }
          testimonial {
            testimonial
            author
            position
          }
          scheduleOnceCalendarId
        }
      }
    }
  `);

  const [closer, setCloser] = useState<Closer | null | undefined>(undefined);

  useEffect(() => {
    getStoredTrackingData({
      rootDomain: ROOT_DOMAIN,
      extraDataKeys: ['closer'],
    }).then(trackingData => {
      const matchingCloser =
        trackingData?.closer &&
        closersData.allSanityCloser.nodes.find(
          closer => closer.slug.current === trackingData?.closer,
        );

      setCloser(matchingCloser || null);
    });
  }, [closersData]);

  return closer;
}

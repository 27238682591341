import { urlJoin } from '@digital-spiders/misc-utils';
import { PageDocumentSlugOnly, PageTypeToPathFunction } from './types/types';

export const AUTHOR_NAME = 'Hook Point';
export const COPYRIGHT_DEFAULT_YEAR = 2023;

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = [DEFAULT_LANG] as const;

export const DEFAULT_SITE_DOMAIN = 'hookpoint.com';
export const DEFAULT_SITE_BASE_URL = 'https://hookpoint.com';

export const OPEN_GRAPH_DESCRIPTION_MAX_LENGTH = 300;

export const HIDE_NAV_QUERY_PARAM = 'nonav';

export const CTA_QUERY_PARAM = 'cta';

// WARNING When updating these constants update in cms constants too
// WARNING When updating these constants update in site create_redirects_file_from_cms too
export const CASE_STUDIES_PREFIX_URL = 'case-studies';
export const ARTICLE_PREFIX_URL = 'blog';
export const INSIGHT_PREFIX_URL = 'insight';

export const PAGE_DOCUMENT_TYPES = [
  'hpWebsitePage',
  'hpWebsiteCaseStudy',
  'hpWebsiteArticle',
  'hpWebsiteInsight',
  'hpWebsiteBlogPage',
  'hpWebsiteCaseStudiesPage',
] as const;

export const PAGE_TYPE_TO_PATH_FUNCTION = {
  hpWebsitePage: (pageDoc: PageDocumentSlugOnly) => urlJoin(pageDoc.slug.current),
  hpWebsiteCaseStudy: (caseStudyDoc: PageDocumentSlugOnly) =>
    urlJoin(CASE_STUDIES_PREFIX_URL, caseStudyDoc.slug.current),
  hpWebsiteArticle: (articleDoc: PageDocumentSlugOnly) =>
    urlJoin(ARTICLE_PREFIX_URL, articleDoc.slug.current),
  hpWebsiteInsight: (insightDoc: PageDocumentSlugOnly) =>
    urlJoin(INSIGHT_PREFIX_URL, insightDoc.slug.current),
  hpWebsiteBlogPage: () => urlJoin(ARTICLE_PREFIX_URL),
  hpWebsiteCaseStudiesPage: () => urlJoin(CASE_STUDIES_PREFIX_URL),
} as const satisfies PageTypeToPathFunction;

// WARNING When updating this constant update in cms constants too
export const VIDEO_TYPES_MAP = {
  tiktok: {
    type: 'tiktok',
    title: 'TikTok',
    formatRegex:
      /^.*https:\/\/(?:m|www|vm)?\.?tiktok\.com\/(?:(?:.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+))|[^/?#&]+).*$/,
  },
  instagram: {
    type: 'instagram',
    title: 'Instagram',
    formatRegex: /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|reel)\/([^/?#&]+).*$/,
  },
  youtube: {
    type: 'youtube',
    title: 'Youtube',
    formatRegex:
      /^(?:https?:\/\/)?(?:(?:(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|shorts\/))|(?:youtu\.be\/))([^/?#&]+).*$/,
  },
  wistia: {
    type: 'wistia',
    title: 'Wistia',
    formatRegex: /^https:\/\/(.*\.)?wistia\.com|wistia\.net\/(medias|embed)\/.*/,
  },
  vimeo: {
    type: 'vimeo',
    title: 'Vimeo',
    formatRegex: /^https:\/\/(player\.)?vimeo\.com\/video\/\d+/,
  },
} as const;

export const DEFAULT_SCHEDULE_ONCE_CALENDAR = 'HookPointDiscovery21';

/**
 *
 * Below here are constants we normally don't need to change or check
 *
 */

export const NETLIFY_FUNCTIONS_BASE_URL = process.env.GATSBY_NETLIFY_FUNCTIONS_BASE_URL!;

export const USE_COOKIES_BANNER = process.env.GATSBY_USE_COOKIES_BANNER;

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;

export const ROOT_DOMAIN = process.env.NODE_ENV === 'development' ? 'localhost:8000' : SITE_DOMAIN;

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
export const SHOULD_RUN_GOOGLE_OPTIMIZE =
  process.env.NODE_ENV === `production` && !!GOOGLE_OPTIMIZE_ID;
